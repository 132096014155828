@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/colors";

///*------------------------------------*\
//
// This file contains globally useful classes
// and html element styles
// also see typography and layout helpers

//\*------------------------------------*/

///*------------------------------------*\
//    Visibility
//\*------------------------------------*/

.is-hidden,
.initial-hide {
    display: none;
}

// form by default have display block
.textarea {
    font-size: 12px;

    &.is-hidden {
        display: none;
    }

    &::placeholder {
        font-size: 12px;
    }
}

.do-not-show {
    display: none !important;
}

//display attributes
.is-block {
    display: block !important;
}

.is-inline {
    display: inline !important;
}

.is-inline-block {
    display: inline-block !important;
}

// Opacity control
.is-opaque {
    opacity: 1;
}

.is-transparent {
    opacity: 0.5;
}

.is-very-transparent {
    opacity: 0.3;
}

.is-invisible,
.faded {
    opacity: 0;
}

.fadable,
.faded {
    transition: opacity 0.5s ease;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

// For fun, css3 filter effects.
// Not supported by Microsoft IE
// http://caniuse.com/#search=filter

.grayed-out {
    filter: grayscale(1);
    opacity: 0.7;
}

.blurred {
    filter: blur(5px);
}

@mixin string-breaker {
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
}

.string-breaker {
    @include string-breaker;
}

///*------------------------------------*\
//    backgrounds
//\*------------------------------------*/

// background overrides

.has-white-bg {
    background: $color-white !important;
}

.has-transparent-bg {
    background: rgba(255, 255, 255, 0.85) !important;
}

.has-no-bg {
    background: none !important;
}

///*------------------------------------*\
//    borders
//\*------------------------------------*/

.has-borders {
    border: 1px $legacy-color-gray-light solid;
}

.has-border-top {
    border-top: 1px $legacy-color-gray-light solid;
}

.has-border-top-light {
    border-top: 1px solid $legacy-color-gray-light;
}

.has-border-bottom {
    border-bottom: 1px $legacy-color-gray-light solid;
}

.has-border-bottom-for-small {
    @include screen-min-width($medium-standard) {
        border-bottom: 1px $legacy-color-gray-light solid;
    }
}

.has-border-bottom-dark-for-small {
    @include screen-min-width($medium-standard) {
        border-bottom: 1px $color-charcoal solid;
    }
}

.has-border-top-dark-for-small {
    @include screen-min-width($medium-standard) {
        border-top: 1px $color-charcoal solid;
    }
}

.has-border-bottom-light {
    border-bottom: 1px solid $legacy-color-gray-light;
}

.has-border-left {
    border-left: 1px $legacy-color-gray-light solid;
}

.has-border-left-for-medium-up {
    @include screen-min-width($medium-standard) {
        border-left: 1px $legacy-color-gray-light solid;
    }
}

.has-border-right-for-medium-up {
    @include screen-min-width($medium-standard) {
        border-right: 1px $legacy-color-gray-light solid;
    }
}

.has-border-right {
    border-right: 1px $legacy-color-gray-light solid;
}

.has-border-right-for-small {
    @include screen-min-width($medium-standard) {
        border-right: 1px $legacy-color-gray-light solid;
    }
}

.has-borders-for-medium-up {
    @include screen-min-width($medium-standard) {
        border: 1px $legacy-color-gray-light solid;
    }
}

.has-no-border {
    border-image-width: 0 !important;
    border-width: 0 !important;
}

.has-no-box-shadow {
    box-shadow: none !important;
}

.has-rounded-corners-5 {
    border-radius: 5px;
}

.has-horizontal-dividers {
    > li {
        border-top: $legacy-color-gray-light 1px solid;
        padding: 0.75rem 0;
    }

    > li:first-child {
        border-top: 0;
    }
}

.has-vertical-dividers {
    > li {
        border-left: $legacy-color-gray-light 1px solid;
    }

    > li:first-child {
        border-left: 0;
    }
}

.svg-ie {
    fill: none;
    stroke: currentColor;
    stroke-width: 2;
}

.svg-stroke-3 {
    stroke-width: 3;
}

.svg-fill-current {
    fill: currentColor;
}

///*------------------------------------*\
//    margins
//\*------------------------------------*/

.margin-top-normal {
    margin-top: 16px;
}

.margin-bottom-normal {
    margin-bottom: 16px;
}

.margin-top-10 {
    margin-top: 10px;
}

///*------------------------------------*\
//    paddings
//\*------------------------------------*/

.has-padding-left-for-small {
    @include screen-min-width($medium-standard) {
        padding-left: 10px !important;
    }
}
