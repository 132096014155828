/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-no-qualifying-type */
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

.byline-list {
    display: inline;
    line-height: 1.2;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        display: inline;
        margin: 0;
        padding: 0;

        a {
            color: $color-hbr-red;
        }
    }

    > li::before {
        content: ", ";
    }

    > li:last-child::before {
        content: ", and ";
    }

    > li:first-child + li:last-child::before {
        content: " and ";
    }

    > li:first-child::before {
        content: "by ";
    }

    .stream-entry.sponsored &,
    &.no-by {
        > li:first-child::before {
            content: "";
        }
    }
}

.plain-inline-list {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        display: inline-block;
        margin: 0;
        padding: 0 12px;
    }

    > li:first-child {
        padding: 0 12px 0 0;
    }

    > li:last-child {
        padding: 0 0 0 12px;
    }

    > li::after {
        content: " ";
    }
}
