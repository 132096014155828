/* stylelint-disable max-nesting-depth */

@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

$li-offset: 40px * 0.3;
$li-interspace: 40px + $li-offset;

.ecommerce-module {
    flex-grow: 1;
    margin-top: 48px;

    .spacing-narrow {
        border-bottom: 1px $legacy-color-border-gray solid;
        font-family: $font-stack-sans-serif;
        padding-bottom: 8px;
    }

    ul {
        list-style-type: none;
        margin: 16px 0 0;

        > li {
            border-bottom: 0;
            margin-bottom: $li-interspace;
            padding: 0;
        }

        > li:last-child {
            margin-bottom: 0;
        }
    }

    figure {
        margin-bottom: 10px;
        width: 90px;
    }

    .title-ellipses-3 {
        height: auto;
    }

    .title-h4 {
        border-top: 0;
        color: $color-iron;
        font-size: 15px;
        letter-spacing: -0.156px;
        margin-bottom: 8px;
        padding: 0;
        text-transform: none;
    }

    h4 {
        border-top: 0;
        color: $color-iron;
        font-size: 15px;
        letter-spacing: (1/96) * -1em;
        margin-bottom: 8px;
        padding: 0;
        text-transform: none;
    }

    a {
        color: $color-charcoal;
    }

    .product-thumb {
        margin: 0 12px 12px 0;
        width: 100px;

        img {
            height: auto;
            width: 100%;
        }
    }

    .hed {
        margin-bottom: 8px;
        width: 200px;

        a {
            font-family: $font-stack-tiempos;
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
        }
    }

    .title-ellipses-3 {
        overflow: hidden;
        position: relative;
    }

    a {
        text-decoration: none;
    }

    .content-type {
        color: $color-pewter;
        font-family: $font-stack-sans-serif;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 16px;
        text-transform: none;
    }

    .standard-cta {
        border: 1px $legacy-color-border-gray solid;
        border-radius: 0.3px;
        display: inline-block;
        font-family: $font-stack-sans-serif;
        font-size: 13px;
        line-height: 1;
        padding: 8px 14px;
    }
}
