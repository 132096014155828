@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.ad {
    align-items: center;
    background-color: #eaf0f1;
    display: flex;
    justify-content: center;
    margin: 24px -20px;
    padding: 42px 0;

    @include screen-min-width($medium-standard) {
        display: none;
    }
}
