/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-no-qualifying-type */
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

.dek {
    color: #000;
    font-family: $font-stack-sans-serif !important;
    font-size: 14px;
    font-weight: 200;
    letter-spacing: (1/64) * -1em;
    line-height: 1.4;
    margin-bottom: $space-small;

    p {
        font-family: $font-stack-sans-serif;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        margin-bottom: 0;
    }
}

.price,
.price--large,
.price--small,
.price-text,
.price-text--large,
.price-text--small {
    color: $color-charcoal;
    font-family: $font-stack-sans-serif;
    letter-spacing: -1px;

    &:not(strong) {
        font-weight: 200;
    }
}

.price,
.price--large,
.price--small {
    &::before {
        content: "$";
    }
}

.price--small,
.price-text--small {
    font-size: 13px;
}

.price,
.price-text {
    font-size: 20px;
    font-weight: 100;
}

.price--large,
.price-text--large {
    font-size: 24px;

    @include screen-min-width($medium-standard) {
        font-size: 28px;
    }

    @include screen-min-width($x-large-plus) {
        font-size: 30px;
    }
}

.interviewname,
.intervieweename {
    font-family: $font-stack-sans-serif;
    font-size: 18px;

    font-weight: bold;
    letter-spacing: (1/96) * -1em;
    line-height: 29px;

    @include screen-min-width($medium-standard) {
        font-size: 19px;
        line-height: 31px;
    }
}

.question {
    display: block;
    font-family: $font-stack-sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: (1/96) * -1em;

    line-height: 1.4;

    @include screen-min-width($medium-standard) {
        font-size: 19px;
        line-height: 31px;
    }
}

p > .question,
p > .answer {
    margin-bottom: -12px;
}

.topic {
    text-transform: uppercase;

    a {
        color: $color-marine;

        &:hover {
            opacity: 0.6;
        }
    }
}

.stream-item-info {
    line-height: 1;

    span.topic {
        font-size: 12px;
    }

    .content-type {
        color: $color-charcoal !important;
        font-size: 12px !important;
    }
}

.content-type,
.content-type--large,
.content-type--small {
    text-transform: uppercase;
}

.topic,
.topic--large,
.topic--small {
    color: $color-hbr-red;

    a {
        color: $color-hbr-red;
    }

    &.inverted {
        color: $color-white;

        a {
            color: $color-white;
        }
    }
}

.utility {
    font-family: $font-stack-sans-serif;
    font-size: 12px;
    font-weight: 200;

    a {
        color: $color-charcoal !important;
        text-transform: capitalize;
        transition: color 300ms ease-out;

        i {
            color: $color-charcoal;
        }

        &:hover {
            opacity: 0.6;
        }
    }
}

.normal-hed {
    font-size: 28px !important;
    font-weight: 900 !important;
    letter-spacing: 0;
    line-height: 0.9 !important;

    @include screen-min-width($medium-standard) {
        font-size: 32px !important;
    }

    @include screen-min-width($x-large-plus) {
        font-size: 34px !important;
    }
}
