@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";

.ad-container {
    background-color: #ebf0f0;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: inset 0 -3px 10px rgb(0 0 0 / 7%);
    box-sizing: border-box;
    margin: 0;
    min-height: 299px;
    overflow: hidden;
    padding: 24px 0;
    text-align: center;

    @media print {
        display: none;
    }
}
