@use "sass:color";
@import "~mfe-articles-shared/scss/variables/colors";

@mixin set-color($color) {
    color: $color !important;

    a {
        color: $color;

        &:hover {
            color: color.adjust($color, $lightness: -10) !important;
        }
    }
}

.info-color {
    color: #239caa !important;
}

.text-gray {
    @include set-color(#666);
}

.text-gray-light {
    @include set-color(#888);
}

.alert-color {
    color: $legacy-color-red-light !important;
}

.alert-color a {
    color: $legacy-color-red-light;
}

.alert-color a:hover {
    color: #cc4401;
}

.alert-color.inverted {
    background: $legacy-color-red-light !important;
    color: $color-charcoal !important;
}

.alert-color.inverted a,
.alert-color.inverted a:hover {
    color: $color-charcoal;
}

.alert-color.border {
    border-color: $legacy-color-red-light;
}

.accent,
.cta,
.primary-color {
    box-shadow: none !important;
    color: $color-hbr-red !important;
}

.bright-blue {
    color: #0caffc !important;
}

.backdrop-darker {
    background: $color-charcoal-dark;
}

.backdrop-lightest {
    background: #eff0f0;
}
