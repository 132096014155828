@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";

.article-promo {
    li {
        font-family: $font-stack-tiempos;
        font-size: 18px;
        margin-bottom: 11px;
    }
}

@media only screen and (min-width: $medium-standard), print {
    .embed-video-hide--half {
        display: inherit !important;
    }

    .embed-video-hide--full {
        display: none !important;
    }
}

@media screen and (max-width: $medium-standard) {
    .embed-video-hide--full {
        display: none !important;
    }
}
