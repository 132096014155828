.stream-item-info {
    line-height: 1;
    margin-bottom: 6px;
}

.stream-utility {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        display: inline-block;
        margin: 0;
        padding: 0 24px 0 0;
    }

    > li:last-child {
        padding: 0;
    }

    .util-organize,
    .util-discard {
        display: none;
    }
}
