/* stylelint-disable selector-class-pattern */

.icon-file-text-o::before {
    content: "\e010";
}

.icon-lt-gt::before {
    content: "<>";
}

.icon-video-camera::before {
    content: "\e025";
}

.icon-check::before {
    content: "\e028";
}

.icon-arrows-alt::before,
.icon-expand::before {
    content: "\e034";
}

.icon-share-square-o::before,
.icon-share::before {
    content: "\e015";
}

.icon-comment-o::before,
.icon-comment::before {
    content: "\e017";
}

.icon-text-height::before {
    content: "\e019";
}

.icon-print::before {
    content: "\e018";
}

.icon-refresh::before {
    content: "\e014";
}

.icon-circle-o::before {
    content: "\e026";
}

.icon-plus::before {
    content: "\e029";
}

.icon-circle::before {
    content: "\e027";
}

.icon-question-circle::before {
    content: "\e030";
}

.icon-user::before {
    content: "\e067";
}

.icon-user-check::before {
    content: "\e068";
}

.icon-shopping-cart::before {
    content: "\e065";
}

.icon-search::before {
    content: "\e007";
}

.icon-bars::before {
    content: "\e001";
}

.icon-facebook-square::before,
.icon-facebook::before {
    content: "\e021";
}

.icon-twitter-square::before,
.icon-twitter::before {
    content: "\e031";
}

.icon-linkedin-square::before,
.icon-linkedin::before {
    content: "\e022";
}

.icon-google-plus-square::before,
.icon-google-plus::before {
    content: "\e033";
}

.icon-times::before {
    content: "\e012";
}

.icon-exclamation::before {
    content: "\e032";
}

.icon-save::before {
    content: "\e010";
}

.icon-save-add::before {
    content: "\e008";
}

.icon-plus-square-o::before,
.icon-create-new-list::before {
    content: "\e029";
}

.icon-shield::before {
    content: "\e023";
}

.icon-executive-summary::before {
    content: "\e024";
}

.icon-full-article::before {
    content: "\e035";
}

.icon-download-pdf::before {
    content: "\e036";
}

.icon-load-more::before {
    content: "\e037";
}

.icon-follow-this::before {
    content: "\e003";
}

.icon-unfollow-this::before {
    content: "\e081";
}

.icon-unfollow-this-full::before {
    content: "\e081";
}

.icon-video-play::before,
.icon-play::before {
    content: "\e046";
}

.icon-daily-stat::before {
    content: "\e038";
}

.icon-daily-idea::before {
    content: "\e043";
}

.icon-management-tip::before {
    content: "\e042";
}

.icon-daily-alert::before {
    content: "\e047";
}

.icon-weekly-hotlist::before {
    content: "\e059";
}

.icon-thumbs-up::before {
    content: "\e005";
}

.icon-thumbs-down::before {
    content: "\e044";
}

.icon-mail::before {
    content: "\e040";
}

.icon-alert::before {
    content: "\e039";
}

.icon-settings::before {
    content: "\e009";
}

.icon-chevron-down,
.icon-angle-down,
.icon-caret-down::before {
    content: "\e049";
}

.icon-chevron-up,
.icon-angle-up,
.icon-caret-up::before {
    content: "\e050";
}

.icon-chevron-left,
.icon-angle-left,
.icon-caret-left::before {
    content: "\e048";
}

.icon-chevron-right,
.icon-angle-right,
.icon-caret-right::before {
    content: "\e002";
}

.icon-organize::before {
    content: "\e041";
}

.icon-shield-reverse::before {
    content: "\e051";
}

.icon-view-more::before,
.icon-ellipsis {
    content: "\e052";
}

.icon-spinner::before,
.icon-loading::before {
    content: "\e053";
}

.icon-zoom::before {
    content: "\e054";
}

.icon-minus::before {
    content: "\e055";
}

.icon-question-mark::before {
    content: "\e056";
}

.icon-reload::before {
    content: "\e057";
}

.icon-information::before {
    content: "\e058";
}

.icon-circle-x::before {
    content: "\e060";
}

.icon-business-bookshelf::before {
    content: "\e061";
}

.icon-pencil::before {
    content: "\e069";
}

.icon-buy-copies::before {
    content: "\e070";
}

.icon-user-info::before {
    content: "\e071";
}

.icon-EPB::before,
.icon-epub::before {
    content: "\e072";
}

.icon-PDF::before,
.icon-pdf::before {
    content: "\e073";
}

.icon-MBI::before,
.icon-mobi::before {
    content: "\e074";
}

.icon-save-folder-saved::before {
    content: "\e075";
}

.icon-AVO::before,
.icon-video::before {
    content: "\e077";
}

.icon-ZIP::before,
.icon-zip::before {
    content: "\e078";
}

.icon-TOOLKIT::before,
.icon-toolkit::before {
    content: "\e079";
}

.icon-newsreader::before {
    content: "\e083";
}

.icon-gift::before {
    content: "\e084";
}

.icon-instagram::before {
    content: "\e085";
}

.icon-pocket::before {
    content: "\e086";
}

.icon-reply-arrow::before {
    content: "\e087";
}

.icon-info-i::before {
    content: "\e088";
}

.icon-download::before {
    content: "\e089";
}

.icon-open-sidebar-arrow::before {
    content: "\e090";
}

.icon-tiktok::before {
    content: "\e091";
}
