/* stylelint-disable max-nesting-depth */

.product-thumb {
    margin: 0 12px 12px 0;
    width: 100px;

    img {
        height: auto;
        width: 100%;
    }
}

.product-thumb-margin {
    height: 150px;
    margin-left: 112px;
    position: relative;

    .stick-to-bottom {
        bottom: 0;
        position: absolute;

        .price {
            font-size: 1.375em;
        }
    }
}

.left {
    float: left !important;
}
