/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-max-compound-selectors */

@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/grid-layout";

@mixin gradient-mask($left: 0, $right: 0) {
    mask-image: linear-gradient(
        90deg,
        rgba(0, 212, 255, $left) 0%,
        rgba(0, 212, 255, 1) 10%,
        rgba(0, 212, 255, 1) 90%,
        rgba(0, 212, 255, $right) 100%
    );
}

.content-rec-wrapper {
    align-self: center;
    flex-direction: column;
    margin-top: 40px;
    max-width: 900px;
    padding: 0 20px;
    width: 100%;

    @include screen-min-width($medium-standard) {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }

    .content-rec-header {
        border-bottom: 1px $legacy-color-border-gray solid;
        font-family: $font-stack-sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 24px 0 16px;
        position: relative;

        .content-rec-title {
            color: $color-iron;
            display: block;
            font-weight: 700;
            letter-spacing: -0.167;
            width: 100%;

            @include screen-min-width($medium-standard) {
                display: inline;
            }
        }

        @include screen-min-width($medium-standard) {
            margin: 0 18px;
        }

        @include screen-min-width($large-standard) {
            max-width: 900px;
        }
    }

    .content-rec-content {
        justify-content: left;
        position: relative;

        a {
            color: $color-charcoal;
            text-decoration: none;
        }

        .content-rec-item {
            border-bottom: 1px $legacy-color-border-gray solid;
            display: flex;
            flex-direction: row-reverse;
            line-height: 1.3;
            max-width: 100%;
            padding: 16px 0;

            @include screen-min-width($medium-standard) {
                border-bottom: 0;
                display: block;
                flex-direction: row;
                max-width: 300px;
                min-width: calc(100% / 4);
                padding: 16px 18px 0;
                position: relative;
                scroll-snap-align: start;
                width: calc(100% / 4);
            }

            @include screen-min-width($medium-standard) {
                min-width: calc(100% / 4);
            }

            @include screen-min-width($large-standard) {
                min-width: calc(#{$article-width} / 4);
            }

            .content-rec-item-image {
                height: auto;
                overflow: hidden;
                width: 112px;

                .content-rec-item-thumbnail {
                    height: auto;
                    width: 100%;
                }

                @include screen-min-width($medium-standard) {
                    margin-bottom: 12px;
                    width: 100%;
                }
            }

            .content-rec-item-title {
                flex: 1;
                font-family: $font-stack-sans-serif;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.25;
                line-height: 1.3;
                margin-bottom: 0;
                margin-right: 16px;

                @include screen-min-width($medium-standard) {
                    flex: 0 1 auto;
                    font-size: 14px;
                    font-weight: 200;
                    margin-right: 0;
                }
            }

            .content-rec-item-summary {
                padding-right: 16px;

                @include screen-min-width($medium-standard) {
                    padding-right: 0;
                }
            }

            .content-rec-index {
                font-size: 13px;
                font-weight: 700;
                opacity: 0.35;
                padding-left: 16px;
                padding-right: 16px;

                @include screen-min-width($medium-standard) {
                    font-size: 13px;
                    margin-bottom: 6px;
                    opacity: 0.35;
                    padding: 0;
                }
            }

            &:hover {
                .content-rec-item-summary {
                    opacity: 0.6;
                }
            }

            &:focus {
                color: inherit;
            }
        }

        &.gradient {
            @include screen-min-width($medium-standard) {
                @include gradient-mask(0, 0);

                &.left {
                    @include gradient-mask(0, 1);
                }

                &.right {
                    @include gradient-mask(1, 0);
                }
            }
        }

        @include screen-min-width($medium-standard) {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
        }
    }
}
