@import "~mfe-articles-shared/scss/variables/colors";

@mixin link--black {
    color: $color-charcoal;

    &:hover {
        opacity: 0.6;
    }
}

.article-promo {
    .link--black,
    .child-links--black a,
    h1 > a,
    h2 > a,
    h3 > a,
    h4 > a,
    h5 > a,
    h6 > a {
        @include link--black;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.webExclusive {
    object {
        width: 100%;
    }
}
