$color-charcoal: #282828;
$color-charcoal-dark: #111;
$color-pewter: #787878;
$color-silver: #888;
$color-hbr-red: #c82502;
$color-iron: #505050;
$color-marine: #0778b1;
$color-white: #fff;
$legacy-color-gray-light: #d5d7d8;
$legacy-color-gray: #c5c8c9;
$legacy-color-red-light: #fe5502;
$legacy-color-maroon: #a01e02;
$legacy-color-gray-lighter: #c8c8c8;
$legacy-color-gray-medium: #b3b3b3;
$legacy-color-gray-dark: #3c3c3c;
$legacy-color-border-gray: #e2e2e2;
