@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/mixins/media-queries";

///*------------------------------------*\
//
// This file should contain useful classes
// for types. Mostly "skin" changes.
// For structural and behavioral changes
// see global and layout helpers

//\*------------------------------------*/

///*------------------------------------*\
//    Changing font-family
//\*------------------------------------*/
// default is GT America

.font-gt-america {
    font-family: $font-stack-sans-serif;
}

.font-roboto-slab {
    font-family: $font-stack-roboto-slab;
}

.font-roboto-slab-compressed {
    font-family: $font-stack-roboto-slab-compressed;
}

.font-lava-std {
    font-family: $font-stack-lava-std;
}

.font-tiempos-headline {
    font-family: $font-stack-tiempos-headline;
}

.font-tiempos-text {
    font-family: $font-stack-tiempos;
}

// If article has list item, I'd like to override GT America with Guardian
.content-area--article {
    ul:not(.byline-list),
    ol,
    dl {
        font-family: $font-stack-lava-std;
    }
}

// by default, the font is GT America,
// but paragraph font is Guardian Egyptian
// or otherwise specified. (System font/fixed width font)
// This is by design as strayed content will not end up as unstyled.
// Specify places that will ALWAYS appear in GT America here
// otherwise, extend

.caption,
.credit,
.artworkcaption p,
.description-text {
    font-family: $font-stack-sans-serif;

    li {
        font-family: $font-stack-sans-serif;
    }
}

//text-override to make those list sans serif

.sans-serif-list,
.link-list {
    font-family: $font-stack-sans-serif;

    li {
        font-family: $font-stack-sans-serif;
        font-size: inherit;
    }
}

// decommission these font. If we ever go back through the XML
// to change the font name classes then remove the below:

.font-whitney,
.font-whitney p {
    font-family: $font-stack-sans-serif;
}

.font-whitney-black {
    font-family: $font-stack-sans-serif;
    font-style: normal;
    font-weight: 800;
}

// helper classes for all sans-serif (currently GT America) options
.font-sans-serif-light {
    font-family: $font-stack-sans-serif;
    font-style: normal;
    font-weight: 200;
}

.font-sans-serif-regular {
    font-family: $font-stack-sans-serif;
    font-style: normal;
    font-weight: 400;
}

.font-sans-serif-bold {
    font-family: $font-stack-sans-serif;
    font-style: normal;
    font-weight: 700;
}

.font-whitney-book {
    font-family: $font-stack-sans-serif;
    font-style: normal;
    font-weight: 400;
}

.font-whitney-bold {
    font-family: $font-stack-sans-serif;
    font-style: normal;
    font-weight: 700;
}

.font-whitney-light {
    font-family: $font-stack-sans-serif;
    font-style: normal;
    font-weight: 200;
}

.font-national-compressed {
    font-family: $font-stack-sans-serif;
}

.font-national {
    font-family: $font-stack-sans-serif;
}

// utility classes for fonts
@each $class,
    $stack
        in (serif $font-stack-roboto-slab-compressed)
        (serif $font-stack-lava-std)
        (sans-serif $font-stack-sans-serif)
        (sans-serif $font-stack-sans-serif)
{
    .font-#{$class} {
        font-family: $stack !important;

        li {
            font-family: $stack;
        }

        table {
            td {
                font-family: $stack;
            }
        }
    }
}

///*------------------------------------*\
//    font-weight
//\*------------------------------------*/

// List of items that ALWAYS appear light weight

@mixin captionCredits($color, $fontWeight: 400) {
    color: $color;
    font-size: 10px;
    font-weight: $fontWeight;
    line-height: 1.1;
    margin-top: 8px;
}

.caption {
    @include captionCredits(#505050);
}

.credit {
    @include captionCredits(#a0a0a0, 200);
}

.artworkcaption {
    color: #666;
    font-size: 11px;
    font-weight: 200;
    line-height: 13px;
    padding-top: 6px;
}

.disclaimer {
    font-family: $font-stack-sans-serif;
    font-size: 12px;
    font-weight: 200;
    line-height: 13;
}

/* weight utility classes */

/* see fonts _scss files for available weights */

/* (not all weights available for all typefaces, "book"/"normal" varies by typeface) */

.font-thin {
    font-weight: 100 !important;
}

.font-light {
    font-weight: 200 !important;
}

.font-weight-book {
    font-weight: 300 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-medium {
    font-weight: 500 !important;
}

.font-weight-semibold {
    font-weight: 600 !important;
}

.font-bold {
    font-weight: 700 !important;
}

.font-heavy {
    font-weight: 900 !important;
}

///*------------------------------------*\
//    font-size
//\*------------------------------------*/
//	Default header line-height is 1.1, anything smaller that
//	is overriding that from class require !important

// 24+
.font-size-xxlarge {
    font-size: 24px !important;

    @include screen-min-width($medium-standard) {
        font-size: 28px !important;
    }

    @include screen-min-width($large-standard) {
        font-size: 30px !important;
    }
}

// 22+
.font-size-xlarge {
    font-size: 22px !important;

    @include screen-min-width($medium-standard) {
        font-size: 25px !important;
    }

    @include screen-min-width($large-standard) {
        font-size: 26px !important;
    }
}

.font-size-larger-x1 {
    font-size: 21px;

    @include screen-min-width($medium-standard) {
        font-size: 23px;
    }

    @include screen-min-width($large-standard) {
        font-size: 24px;
    }
}

// 18+
.font-size-larger {
    font-size: 18px !important;

    @include screen-min-width($medium-standard) {
        font-size: 21px !important;
    }

    @include screen-min-width($large-standard) {
        font-size: 22px !important;
    }
}

// 18
.font-size-large {
    font-size: 18px !important;
}

// 16
.font-size-default {
    font-size: 16px !important;
}

// 14
.font-size-small {
    font-size: 14px !important;
}

// 12
.font-size-xsmall {
    font-size: 12px !important;
}

// pixel override
.font-size-78 {
    font-size: 78px !important;
}

.font-size-32 {
    font-size: 32px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-21 {
    font-size: 21px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-19 {
    font-size: 19px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-10 {
    font-size: 0.625rem !important;
}

.font-size-9 {
    font-size: 9px !important;
}

// relative size helpers
.font-size-100pct {
    font-size: 100%;
}

.font-size-40pct {
    font-size: 40%;
}

// large heds! Overrides with combination with h1
// Is there a better way to do this without
// qualifying with h1 element?

.normal-hed {
    font-size: 28px;

    font-weight: 900;
    letter-spacing: 0;
    line-height: 0.9;

    @include screen-min-width($medium-standard) {
        font-size: 32px;
    }

    @include screen-min-width($large-standard) {
        font-size: 34px;
    }
}

.large-hed {
    font-size: 32px;
    font-weight: 900;
    letter-spacing: -1;
    line-height: 0.9;
    padding-bottom: 16px;

    @include screen-min-width($medium-standard) {
        font-size: 42px;
    }

    @include screen-min-width($large-standard) {
        font-size: 48px;
    }
}

.xlarge-hed {
    font-size: 32px;

    font-weight: 900;
    letter-spacing: -2;
    line-height: 0.9;

    @include screen-min-width($medium-standard) {
        font-size: 40px;
    }

    @include screen-min-width($large-standard) {
        font-size: 46px;
    }
}

.xxlarge-hed {
    font-size: 48px;

    font-weight: 900;
    letter-spacing: -2;
    line-height: 0.9;

    @include screen-min-width($medium-standard) {
        font-size: 64px;
    }

    @include screen-min-width($large-standard) {
        font-size: 78px;
    }
}

.xxxlarge-hed {
    font-size: 50px;

    font-weight: 900;
    letter-spacing: -2;
    line-height: 0.9;

    @include screen-min-width($medium-standard) {
        font-size: 70px;
    }

    @include screen-min-width($large-standard) {
        font-size: 90px;
    }
}

.large-hed.subheader,
.xlarge-hed.subheader,
.xxlarge-hed.subheader,
.xxxlarge-hed.subheader,
.subheader {
    font-weight: 200;
}

.large-hed.subheader,
.xlarge-hed.subheader,
.xxlarge-hed.subheader,
.xxxlarge-hed.subheader {
    letter-spacing: calc(1 / 32) * -1em;
}

.article-hed {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.01;
    line-height: 33px;

    @include screen-min-width($medium-standard) {
        font-size: 40px;
        line-height: 41px;
    }

    @include screen-min-width($large-standard) {
        font-size: 55px;
        line-height: 47px;
    }
}

.sponsored-article-hed {
    font-size: 32px;
    font-weight: 500;

    letter-spacing: -0.015em;
    line-height: 35px;

    @include screen-min-width($medium-standard) {
        font-size: 42px;
    }

    @include screen-min-width($large-standard) {
        font-size: 48px;
    }

    @include screen-min-width($medium-standard) {
        line-height: 50px;
    }
}

.circulator-text-header {
    color: #393d41;
    font-size: 12px;
}

///*------------------------------------*\
//    line-height
//\*------------------------------------*/
//

// line height utility classes
.line-height-very-loose {
    line-height: 2.2 !important;
}

.line-height-loose {
    line-height: 1.8 !important;
}

.line-height-normal {
    line-height: 1.4 !important;
}

.line-height-snug {
    line-height: 1.25 !important;
}

.line-height-more-snug {
    line-height: 1.0625 !important;
}

.line-height-tight {
    line-height: 1 !important;
}

.line-height-very-tight {
    line-height: 0.9 !important;
}

.line-height-very-very-tight {
    line-height: 0.8 !important;
}

///*------------------------------------*\
//    baseline-shift
//\*------------------------------------*/
// Best used in span -- mostly adjusting icon font etc
// Notice it is em not rem

.baseline-shift-up-more {
    position: relative;
    top: -0.25em;
}

.baseline-shift-up {
    position: relative;
    top: -0.125em;
}

.baseline-shift-down {
    position: relative;
    top: 0.125em;
}

.baseline-shift-down-more {
    position: relative;
    top: 0.25em;
}

///*------------------------------------*\
//    letter-spacing
//\*------------------------------------*/
//
// letter-spacing utility classes
// Those are done in EMs, not REMs, on purpose

.spacing-very-wide {
    letter-spacing: calc(2 / 16) * 1em !important;
}

.spacing-wide {
    letter-spacing: calc(1 / 16) * 1em !important;
}

.no-letter-space,
.spacing-neutral {
    letter-spacing: 0 !important;
}

.spacing-narrow {
    letter-spacing: calc(1 / 32) * -1em !important;
}

.spacing-very-narrow {
    letter-spacing: calc(2 / 32) * -1em !important;
}

.spacing-very-very-narrow {
    letter-spacing: calc(3 / 32) * -1em !important;
}

// PLEASE KEEP THOSE: Those are used by buttons in too many places to remove.
// but DEPRECIATED: above rules should be used
.letter-spacing-narrow {
    letter-spacing: -1 !important;
}

.letter-spacing-normal,
.font-tight {
    letter-spacing: 0 !important;
}

.letter-spacing-wide {
    letter-spacing: 1px !important;
}

.letter-spacing-very-wide {
    letter-spacing: 2px !important;
}

///*------------------------------------*\
//    GT AMERICA -- Letter Spacing Classes
//\*------------------------------------*/
//
.font-tighten-slight {
    letter-spacing: calc(1 / 96) * -1em;
}

.font-tighten-more {
    letter-spacing: calc(1 / 64) * -1em;
}

.font-tighten-most {
    letter-spacing: calc(1 / 32) * -1em;
}

.font-loosen-more {
    letter-spacing: calc(1 / 64) * 1em;
}

///*------------------------------------*\
//    font-style - spacing and transform
//\*------------------------------------*/
//
.italic,
.italics,
.oblique,
.mediatitle {
    font-style: italic;
}

.font-style-normal {
    font-style: normal;
}

.small-caps {
    font-variant: small-caps;
}

.uppercase {
    text-transform: uppercase !important;
}

.capitalized {
    text-transform: capitalize !important;
}

.lowercase {
    text-transform: lowercase !important;
}

.preserve-case,
.preservecase,
.nocase {
    text-transform: none !important;
}

.text-transform-normal {
    text-transform: none;
}

.nowrap {
    white-space: nowrap;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

///*------------------------------------*\
//    underlined text
//\*------------------------------------*/
//
.is-underlined {
    text-decoration: underline !important;
}

.text--align-center {
    text-align: center;
}

.text--align-left {
    text-align: left !important;
}

///*------------------------------------*\
//    font color
//\*------------------------------------*/
//

.light-gray {
    color: $color-pewter !important;
}
