@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

html {
    box-sizing: border-box;
    box-sizing: border-box;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    border: 0;
    box-sizing: inherit;
    box-sizing: inherit;
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

body {
    border: 0;
    margin: 0;
    padding: 0;
}

.no-scroll {
    overflow: hidden;
}
