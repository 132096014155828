$font-stack-sans-serif: "GT America", helvetica, "Helvetica Neue", verdana,
    arial, sans-serif;
$font-stack-tiempos: "Tiempos Text", Georgia, "Times New Roman";
$font-stack-tiempos-headline: "Tiempos Headline", Georgia, "Times New Roman";
$font-stack-roboto-slab: "Roboto Slab", Georgia, "Times New Roman";
$font-stack-roboto-slab-compressed: "Roboto Slab Thin", Georgia,
    "Times New Roman";
$font-stack-lava-std: "Lava Std", Georgia, "Times New Roman";
$font-hbr-icons: "HBRIcons";
$font-stack-lava: "Lava Std", Georgia, "Times New Roman";
