/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-no-qualifying-type */
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.article-sidebar {
    .credit {
        box-sizing: border-box;
        color: #a0a0a0;
        display: block;
        font-size: 10px;
        font-weight: 200;
        line-height: 1.1px;
        margin-top: 8px;
    }

    .dek {
        color: $color-iron;
        display: block;
        font-family: $font-stack-sans-serif;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0 !important;
        padding-bottom: 12px;
    }

    div.dek {
        color: #000;
        font-size: 14px;
        line-height: 1.4;
    }

    .small-block-grid-1 > li {
        list-style: none;
        width: 100%;
    }
    @include screen-min-width($medium-plus) {
        .medium-block-grid-3 > li {
            list-style: none;
            width: 33.33%;
        }

        [class*="block-grid-"] > li {
            display: block;
            float: left;
            height: auto;
            padding: 0 20px 10px;
        }
    }

    .columns {
        float: left;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
    }

    .small-12 {
        width: 100%;
    }

    &.sidebar--full + p {
        clear: both;
    }

    tbody {
        td {
            line-height: 1.273;
        }
    }
}
