/* stylelint-disable max-nesting-depth */
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

.sidebar--carousel {
    .slide-context {
        &.only-text {
            color: $color-charcoal;
            font-family: $font-stack-sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
        }
    }
}
