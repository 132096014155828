/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-no-qualifying-type */
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

$space-normal: 16px;
$space-small: 6px;
$space-medium: 12px;
$space-large: 24px;
$margin-top-4x-normal: 70px;

.article-promo {
    clear: both;
    margin-top: 9px !important;

    + h2,
    + h3,
    + h4,
    + div {
        padding-top: 5px;
    }

    .promo-trigger {
        width: 100%;
    }

    a img {
        border: 0;
        display: inline-block;
        height: 24.76px;
        vertical-align: middle;
    }

    h3 {
        font-family: $font-stack-sans-serif;
        font-size: 19px;
        line-height: 1.6;
    }

    h6 {
        font-family: $font-stack-sans-serif;
        font-size: 19px;
        line-height: 1;
    }

    span {
        font-family: $font-stack-sans-serif;
    }

    li {
        font-family: $font-stack-tiempos;
        font-size: 18px;
        margin-bottom: 11.2px !important;
    }

    a[href] {
        text-decoration: none;
    }

    a,
    a:hover {
        color: $color-charcoal;
    }
}

.promo--half {
    @include screen-min-width($medium-standard) {
        float: left;
        margin-right: 4%;
        width: 46%;
    }
}

.sidebar--half-right,
.promo--half-right {
    @include screen-min-width($medium-standard) {
        float: right;
        margin-left: 4%;
        width: 46%;
    }
}

.sidebar--half {
    @include screen-min-width($medium-standard) {
        float: left;
        margin-right: 56px !important;
        max-width: 240px !important;
    }
}

.icon {
    display: inline-block;
    font-family: $font-hbr-icons;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
}

.font-weight-medium {
    font-weight: 500 !important;
}

.font-sans-serif {
    font-family: $font-stack-sans-serif;

    li {
        font-family: $font-stack-sans-serif;
    }
}

.font-size-small {
    font-size: 14px !important;
}

.line-height-loose {
    line-height: 1.8 !important;
}

.font-light {
    font-weight: 200 !important;
}

.promo--half,
.promo--half-right,
.promo--right,
.promo--wide-right,
.promo--inline {
    > h4,
    > .personalization-placement > h4 {
        // keep border-top here the same as border-bottom for li elements below
        border-top: 1px #abafb0 solid;
        font-family: $font-stack-sans-serif;
        font-size: 13px;
        line-height: 1;
        margin-bottom: $space-small;
        padding-top: $space-medium;
        text-transform: uppercase;
    }

    > .personalization-placement {
        .content-type {
            font-size: 12px;
        }

        .product-thumb-margin {
            h6 {
                font-size: 16px;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }

    > .promo-contents {
        h6 {
            font-size: 15px;

            a {
                &:hover {
                    opacity: 0.6;
                }
            }
        }

        .medium-magazine-cover + div {
            a {
                color: $color-marine;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0.03px;
                text-transform: capitalize;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }

    > .promo-contents > ul:not(.stream-utility),
    > .personalization-placement > ul:not(.stream-utility) {
        line-height: 1;
        margin-left: 0;

        .byline {
            color: $color-silver;
        }

        > li {
            // keep border-bottom here the same as border-top for h4 elements above
            border-bottom: 1px #abafb0 solid;
            display: block;
            font-family: $font-stack-sans-serif;
            overflow: hidden;
            padding: $space-medium + $space-small 0 $space-medium;

            &:first-of-type {
                padding-top: 0;
            }

            > .stream-item-info {
                margin-bottom: 0;

                > ul {
                    line-height: 1;
                }
            }
        }
    }
}

.promo--right {
    > .personalization-placement > ul:not(.stream-utility) {
        > li {
            padding: $space-large + $space-small 0 $space-large + $space-medium;
        }

        &:first-of-type {
            padding-top: 0;
        }
    }
}

.promo-contents > ul:not(.stream-utility) {
    li {
        .overflow-auto {
            overflow: initial !important;
        }

        .stream-item-info {
            .topic {
                display: none;
            }

            .content-type {
                color: #666 !important;
                font-size: 13px !important;
                font-weight: bold;
                text-transform: capitalize;
            }

            .stream-utility {
                .util-save,
                .util-share {
                    display: none;
                }
            }

            a[js-target="add"] {
                border-color: $color-hbr-red;
                border-radius: 3px;
                border-style: solid;
                border-width: 1px;
                color: $color-hbr-red;
                cursor: pointer;
                display: inline-block !important;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0.24px;
                line-height: normal;
                margin-left: 0 !important;
                margin-top: 10px;
                padding-bottom: 10px;
                padding-left: 16px !important;
                padding-right: 16px !important;
                padding-top: 11px !important;
                position: relative;
                text-align: center;
                text-decoration: none;
                text-transform: none !important;

                &:hover {
                    border-color: $legacy-color-maroon;
                    color: $legacy-color-maroon;
                }
            }
        }
    }
}

.promo--half-right,
.promo--wide-right {
    @include screen-min-width($x-large-plus) {
        padding-left: $space-large;
    }
}

.promo--in-content {
    margin: 0 !important;
    padding-bottom: 32px;
    padding-top: 12px;

    h4 {
        color: $color-iron;
        font-family: $font-stack-sans-serif;
        font-size: 14px;
        font-weight: 200;
        margin: 0;
        padding-bottom: 1.6px;
    }

    .promo-contents {
        margin: 0;
        padding-top: 8px;

        p {
            font-family: $font-stack-sans-serif !important;
            font-size: 16px !important;
            font-weight: 200;
            line-height: 1.5 !important;
            margin: 0;

            a {
                border-bottom: 3px #c3dddb solid;

                &:hover {
                    opacity: 0.6;
                }

                &:focus {
                    color: $color-charcoal;
                }
            }
        }
    }
}

.promo--inline {
    border-bottom: 1px $legacy-color-border-gray solid;
    margin: $space-large 0;
    overflow: hidden;
    padding-bottom: $space-medium;

    p {
        margin-bottom: 32px;
    }
}

.promo-title {
    font-size: 13px !important;
    letter-spacing: (1/64) * 1em;
    line-height: 1;
    text-transform: uppercase;
}

.add-to-cart {
    display: block !important;

    a {
        box-shadow: none !important;
        font-family: $font-stack-sans-serif !important;
    }
}

.sponsored {
    hr {
        border: #e2e2e2 solid;
    }

    ul.has-vertical-dividers {
        > li {
            border-left: 1px #e2e2e2 solid;
        }
    }
}

.article-sidebar,
.sidebar {
    border-bottom: 1px $legacy-color-border-gray solid;
    border-top: 1px #e2e2e2 solid;
}

.promo--half,
.promo--half-right,
.promo--right,
.promo--wide-right,
.promo--inline {
    > h4,
    > .personalization-placement > h4 {
        border-top: 1px #e2e2e2 solid;
    }

    > .promo-contents > ul:not(.stream-utility),
    > .personalization-placement > ul:not(.stream-utility) {
        > li {
            border-bottom: 1px $legacy-color-border-gray solid;
        }
    }
}

.divider {
    border-top: 1px #e2e2e2 solid;
}

// Responsive video
.hide-for-mobile {
    display: none !important;
}

.hide-for-small-only {
    display: none;
    @include screen-min-width($medium-standard) {
        display: block !important;
    }
}

.show-for-small-only {
    display: block;
    @include screen-min-width($medium-standard) {
        display: none !important;
    }
}

.show-for-medium-up {
    @include screen-min-width($medium-standard) {
        display: block !important;
    }
}

.show-for-mobile {
    display: block;
}

.hide-for-medium-up {
    @include screen-min-width($medium-standard) {
        display: none !important;
    }
}

.hide-for-large-up {
    @include screen-min-width($large-standard) {
        display: none !important;
    }
}

.artwork-narrow {
    @include screen-min-width($medium-standard) {
        float: left;
        padding-right: $space-normal + $space-large;
        width: 50%;
    }
    padding-bottom: $space-normal;
}

h3,
h4,
h6 {
    &.inline-helper {
        display: inline;

        + p {
            display: inline;

            &::after {
                content: " ";
                display: block;
                margin-bottom: 20px;
            }
        }
    }
}

h4.inline-helper {
    font-size: 17px;
    font-style: italic;
    font-weight: 400;
    line-height: 27.74px;

    @include screen-min-width($medium-standard) {
        font-size: 19px;
        line-height: 31px;
        margin-bottom: 0 !important;
    }
}

.book-promo-container {
    display: flex;
    flex-direction: column;

    figure {
        img {
            max-width: none;
            padding-bottom: 12px;
            width: 205px !important;

            @include screen-min-width($medium-standard) {
                border-right: 1px solid #e5e5e5;
            }
        }
    }

    @include screen-min-width($medium-standard) {
        flex-direction: row;
    }

    .book-promo-content {
        @include screen-min-width($medium-standard) {
            padding-left: 24px;
        }

        p {
            font-family: $font-stack-tiempos;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.01px;
            line-height: 19px;
            margin-bottom: 32px;

            @include screen-min-width($medium-standard) {
                font-weight: 15px;
                line-height: 20px;
            }

            strong,
            a {
                font-family: $font-stack-sans-serif;
                font-weight: 700;
            }

            a {
                border-bottom: 3px solid #c3dddb;
                letter-spacing: 0.14px;
                line-height: 17.61px;
            }
        }
    }
}
