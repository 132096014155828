@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

.skip-link {
    font-family: $font-stack-sans-serif;
    position: absolute;
    right: 100%;
    text-decoration: none;
    top: 0;
    z-index: 700;
}

.skip-link:focus {
    background-color: $color-white;
    border: $color-charcoal 3px solid;
    color: $color-charcoal;
    padding: 20px;
    right: auto;
    top: 85px;
}
